<template>
  <section class="subscribe-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="subscribe-section__header col-lg-7">
          <h1 class="subscribe-section__title">
            Get Full Access to Resume Creator & More
          </h1>
          <p class="subscribe-section__description">
            Build your best resume yet. Subscribe to a plan that suits your
            requirements.
          </p>
        </div>
      </div>
      <div v-if="subscriptionPlans.length" class="row justify-content-center">
        <SubscribePlanCard
          v-for="(plan, index) in subscriptionPlans"
          :key="index + plan.identity"
          :plan="plan"
          @choosePlan="goToPay(plan.identity)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import SubscribePlanCard from "@/components/subscribe-steps/SubscribePlanCard";
import { AUTH_NAMES, SUBSCRIBE_STEPS_NAMES } from "@/helpers/rout-constants";
import { mapGetters } from "vuex";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import * as FullStory from "@fullstory/browser";

export default {
  name: "PricingFormV1",
  mixins: [mixpanelMixin],
  components: {
    SubscribePlanCard,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      subscriptionPlans: "subscribe/getSubscriptions",
      user: "user/user",
    }),
  },
  methods: {
    goToPay(planId) {
      this.$router.push({
        name: this.isLoggedIn
          ? SUBSCRIBE_STEPS_NAMES.PAYMENT_PAGE
          : AUTH_NAMES.LOGIN,
        params: {
          planId,
        },
      });
    },
    async getUserData() {
      try {
        await this.$store.dispatch("user/getUser");
      } catch (e) {
        console.error(e);
      }
    },
  },

  async created() {
    this.mixpanelTrack("V2-PRICING");

    if (this.isLoggedIn) {
      await this.getUserData();
      FullStory.setUserVars({
        email: this.user.email,
      });
    }

    await this.$store.dispatch("loader/pending");
    await this.$store.dispatch("subscribe/getAllSubscriptionPlans");
    await this.$store.dispatch("loader/done");
  },
};
</script>

<style scoped lang="scss">
.subscribe-section {
  padding: 80px 0 80px;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    padding: 80px 0 105px 0;
    text-align: center;
  }

  &__title {
    @media (max-width: map_get($grid-breakpoints, lg)) {
      font-size: 24px;
    }
    @media (max-width: 375px) {
      font-size: 20px !important;
    }
  }

  &__description {
    font-size: 14px;
    line-height: 140%;
    color: $light-gray;
    margin-top: 16px;
    margin-bottom: 32px;
    @media (max-width: 375px) {
      font-size: 12px !important;
      margin-top: 8px !important;
      margin-bottom: 14px !important;
    }
  }
}
</style>
