<template>
  <div class="col-md-8 col-lg-auto plan-card-wrap">
    <div class="plan-card">
      <figure class="plan-card__icon">
        <img v-if="plan.photo" :src="plan.photo" alt="" />
      </figure>
      <h3 class="plan-card__title text-center">{{ plan.title }}</h3>

      <div class="plan-card__subtitle">
        <span v-if="plan.title == '14-Day Full Access'"> Most Popular </span>
      </div>

      <div class="plan-card__price price">
        <span class="price__currency">$</span>
        <p class="price__total">{{ plan.price }}</p>
      </div>
      <div class="plan-card__btn">
        <button @click="choosePlan" class="btn btn--secondary">
          <span class="btn__text">Continue</span>
        </button>
      </div>
      <ul class="plan-card__options plan-options">
        <li
          v-for="option in plan.options"
          :key="option"
          class="plan-options__item"
        >
          <span>{{ option }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubscribePlanCard",
  props: {
    plan: {
      identity: String,
      title: String,
      options: Array,
      price: [Number, String],
      photo: String,
    },
  },
  methods: {
    choosePlan() {
      this.$emit("choosePlan");
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-card-wrap {
  margin-bottom: 40px;
}

.plan-card {
  padding: 40px 32px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 40px;
  width: 100%;
  height: 100%;
  text-align: left;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    max-width: 410px;
  }

  @media (max-width: 414px) {
    padding: 20px !important;
    .plan-card__title {
      margin-top: 15px !important;
      margin-bottom: 15px !important;
      font-size: 18px !important;
    }
    .plan-card > div:nth-child(3) {
      display: inline-flex !important;
      margin: 15px 0 !important;
    }
  }
  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
      display: inline-flex;
      width: 70px;
      height: 70px;
      object-fit: contain;
      object-position: center center;
    }
  }

  &__title {
    margin-top: 32px;
    font-size: 20px;
    line-height: 130%;
    @media (max-width: 320px) {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  &__subtitle {
    color: red;
    margin-bottom: 10px;
    min-height: 25px;
  }

  &__options {
    font-size: 14px;
    line-height: 140%;
  }

  &__price {
    padding-bottom: 32px;
    color: $primary;
    font-size: 2.5rem;
    font-weight: 600;
    position: relative;
    @media (max-width: 320px) {
      padding-bottom: 16px;
    }
    @media (max-width: map_get($grid-breakpoints, md)) {
      padding-bottom: 10px;
      font-size: 2.2rem;
    }
    p.price__total {
      line-height: 130%;
    }

    .price__currency {
      font-size: 24px;
      line-height: 130%;
      font-weight: 400;
      position: absolute;
    }

    .price__total {
      padding-left: 23px;
    }
  }

  &__btn {
    padding-bottom: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.plan-card:last-of-type {
  margin-bottom: 15px;
}

.plan-options {
  &__item {
    margin-bottom: 18px;
    position: relative;

    &:before {
      content: "";
      display: block;
      background-size: cover;
      background-image: url("~@/assets/images/subscribe-plans/plan-options-checkbox-icon.svg");
      width: 18px;
      height: 18px;
      position: absolute;
    }

    &:last-of-type {
      margin: 0;
    }
  }

  span {
    padding-left: 30px;
    display: block;
  }
}
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  border-radius: 8px;
  height: 55px;
  padding: 10px 20px;
  min-width: 200px;
  position: relative;
  cursor: pointer;

  &__text {
    transition: all 0.3s ease;
  }
  &--secondary {
    border: 1px solid $primary;

    .btn__text {
      color: $primary;
    }

    &:hover {
      background-color: $primary;
      color: #ffffff;

      .btn__text {
        color: #ffffff;
      }
    }
  }
}
</style>
